import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import invoice from "../../images/invoicegener.png";
import Popup from "./InvoicePopUp";
import download from "../../images/downlaod.png";
import DownloadPDF from "./DownloadPDF";
import DropdownEntity from "./DropdownEntity";
import { Dropdown,  DropdownChangeEvent } from "primereact/dropdown";
import GetInvoices from "./GetInvoices";
import { InputText } from "primereact/inputtext";
import GetCompanyInfo from "./GetCompanyInfo";
import SetInvoice from "./SetInvoice";
import ChangePlan from "./ChangePlan";
import ChangeLicenses from "./ChangeLicenses";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

interface PlanServiceProp {
  email: string;
  companyId: string;
  onInvoiceChange: () => void; // callback from parent to fetch data
  onPlanChange: () => void; // callback from parent to fetch data
  onSaveChanges: () => void; // callback from parent to fetch data
}

interface Invoices {
  id: number | string;
  headsets: number | string;
  price: number | string;
  link: string;
  status: number;
  type: string;
}

interface Plan {
  name: string;
  label: string;
}

const PlanService: React.FC<PlanServiceProp> = ({ email, companyId,onInvoiceChange, onPlanChange, onSaveChanges  }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [link, setLink] = useState<string | null>(null);
  const [linkCheck, setLinkCheck] = useState<boolean>(true);
  const [InvoiceArray, setInvoiceArray] = useState<Invoices[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<
    Invoices | "None" | any
  >(null);
  const [selectedId, setSelectedId] = useState<number | string | null>(null);
  const [selectedHeadsets, setSelectedHeadsets] = useState<any>(null);
  const [selectedIPrice, setSelectedPrice] = useState<any>(
    null
  );
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [disabled, setDisabled] = useState<boolean>(true)
  const [allBlock, setAllBlock] = useState<boolean>(false)

  const plansArray: Plan[] = [
    {
      name: "Free",
      label: "Free",
    },
    {
      name: "Standart",
      label: "Standard",
    },
    {
      name: "Premium",
      label: "Premium",
    },
    {
      name: "Enterprise",
      label: "Enterprise",
    },
  ];

  useEffect(() => {
    console.log("Fetching data...");
    fetchData();
  }, []);

  useEffect(() => {
    console.log("Link updated:", link);
  }, [link]);

  const fetchData = async () => {
    try {
      const invoiceResponse = await GetInvoices(companyId);
      const selectedInvoices = invoiceResponse.data.data.map((item: any) => ({
        id: item.invoice_id,
        headsets: item.number_licenses,
        price: item.price,
        status: item.status,
        link: item.url,
        type: item.type,
      }));
      console.log(selectedInvoices)

    //   selectedInvoices.unshift({
    //     id: "none",
    //     headsets: "n/a",
    //     price: "n/a",
    //     link: "n/a",
    //     status: 0,
    //     type: "n/a",
    // });

      setInvoiceArray(selectedInvoices);

      const companyResponse = await GetCompanyInfo(companyId);
      if(companyResponse.data.company.invoice === 0 && companyResponse.data.company.plan == "Free" || companyResponse.data.company.invoice === 1){
        setDisabled(false)
      }
      setSelectedHeadsets(companyResponse.data.company.payedLicense);
      setSelectedPrice(companyResponse.data.company.monthly_bill);
      const current = plansArray.find(
        (plan) => plan.name === companyResponse.data.company.plan
      );
      if (current) setSelectedPlan(current);
      console.log(companyResponse.data.company)

      if (companyResponse.data.company.invoice === 1) {
        console.log("ASDASDAS")
        const activeInvoice = selectedInvoices.find(
          (invoice) => invoice.status === 1
        );
        setLink((activeInvoice?.link).slice(8) || null);
        setLinkCheck(false);        
        console.log(activeInvoice)
        setSelectedInvoice(activeInvoice || null);
      } else {
       setSelectedInvoice("None");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/company/payment/status/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data.data)
      if(response.data.data == "Stripe"){
        setAllBlock(true)
        console.log("Setted")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectionChange = (event: DropdownChangeEvent) => {
    const selected = event.value as Invoices | "None" | null;

    setSelectedInvoice(selected);

    if (selected && selected !== "None") {
        SetInvoice(companyId, 1, selected.id as number, selected.type as string);
        setLink((selected.link as string).slice(8) || null);
    } else {
        SetInvoice(companyId, 1, null, null);
        setLink(null);
    }

    toast.success("Invoice has been chosen", {
        position: toast.POSITION.BOTTOM_RIGHT,
    });
    onInvoiceChange();
};
const handlePlanChange = async (event: DropdownChangeEvent) => {
  const selected = event.value as Plan;
  
  // Check if the selected plan is "Free"
  if (selected.name === "Free" && selectedInvoice!=null) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_LINK}/change/invoice/status`,
        {
          companyId: companyId,
          status:0,
          invoiceId: selectedInvoice.id,
          type: selectedInvoice.type,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Free plan test request successful:", response.data);
      toast.success("Free plan activated and test request sent", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      fetchData();
      
    } catch (error) {
      console.error("Error in Free plan test request:", error);
      
    }
    
  }

  // Change the plan
  await ChangePlan(companyId, selected.name);
  setSelectedPlan(selected);
  toast.success("Plan has been changed", {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
   onPlanChange();
};


  const handleSaveChanges = () => {
    ChangeLicenses(companyId, selectedHeadsets, selectedIPrice);
    setSelectedPrice(selectedIPrice)
    setSelectedHeadsets(selectedHeadsets)
    toast.success("Changes saved", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    onSaveChanges();
    
  };

  const itemTemplate = (option: Invoices) => {
    return (
      <div className="row" style={{ gap: "5px", display: "flex", alignItems: "center" }}>
        <div style={{ fontFamily: "Inter" }}>{option.id === "none" ? "None" : option.id}</div>
        {option.id !== "none" && (
          <>
            <div
              style={{
                color: "#2E3A4BB2",
                fontFamily: "Inter",
                marginLeft: "auto",
              }}
            >
              {option.headsets} h /
            </div>
            <div
              style={{ color: "#2E3A4BB2", fontFamily: "Inter", marginLeft: "0px" }}
            >
              ${option.price}
            </div>
          </>
        )}
      </div>
    );
  };
  

  const planTemplate = (option: Plan) => {
    return <div style={{ fontFamily: "Inter" }}>{option.label}</div>;
  };

  return (
    <div>
      <div className="column">
        <div className="row">
          <Button
            className="generate-invoice"
            style={{
              height: "36px",
              width: "234px",
              marginTop: "26px",
              marginLeft: "20px",
            }}
            onClick={() => {
              setPopupVisible(true);
            }}
            disabled={disabled && allBlock}  
          >
            <img src={invoice} style={{ width: "20px", height: "20px" }} />
            <div className="button-text">Generate invoice</div>
          </Button>
          <Button
            style={{
              height: "36px",
              marginTop: "26px",
              marginLeft: "10px",
              width: "45px",
              position: "relative",
              background: "#FFFFFF",
              color: "#005DFF",
              border: "2px solid #005DFF",
            }}
            onClick={() => {
              console.log("Downloading with link:", link); // Ensure this logs the correct link
              DownloadPDF(link); // Pass the link directly
            }}
            disabled={linkCheck && disabled && allBlock}
          >
            <img
              src={download}
              style={{
                width: "20x",
                height: "20px",
                marginLeft: "-7px",
              }}
            />
          </Button>
          <div
            className="column"
            style={{ width: "234px", marginTop: "0px", marginLeft: "10px" }}
          >
            <div style={{ fontFamily: "Inter", fontWeight: "520" }}>
              Invoice №
            </div>           
            {selectedInvoice != null && (<Dropdown    
                className="custom-dropdown"                   
                value={selectedInvoice}
                onChange={handleSelectionChange} 
                itemTemplate={itemTemplate} 
                options={InvoiceArray}  
                placeholder="None"
                optionLabel={itemTemplate as any} 
                disabled={disabled && allBlock}                     
            />)}
          </div>
        </div>
        <div className="row" style={{ width: "70%" }}>
          <div
            className="column"
            style={{ marginTop: "20px", marginLeft: "20px" }}
          >
            <div
              style={{ fontFamily: "Inter", fontWeight: "520", width: "234px" }}
            >
              Change plan
            </div>
            {selectedPlan != null && (
              <Dropdown
                className="custom-dropdown"
                value={selectedPlan}
                options={plansArray}
                optionLabel={planTemplate as any}
                onChange={handlePlanChange}
                itemTemplate={planTemplate}
                disabled={allBlock}
              />
            )}
          </div>
          <div className="column">
            <div
              style={{
                fontWeight: "400",
                fontFamily: "Inter",
                marginTop: "20px",
                width: "234px",
              }}
            >
              Licenses purchesed
            </div>
            <InputText
              className="custom-inputText-style"
              style={{ width: "100%", height: "40px" }}
              type="number"
              value={selectedHeadsets}
              onChange={(e) => setSelectedHeadsets(e.target.value)}
              disabled={allBlock}
            />
          </div>
          <div className="column">
            <div
              style={{
                fontWeight: "400",
                fontFamily: "Inter",
                marginTop: "20px",
                width: "234px",
              }}
            >
              Total ($)
            </div>
            <InputText
              className="custom-inputText-style"
              style={{ width: "100%", height: "40px" }}
              type="number"
              value={selectedIPrice}
              onChange={(e) => setSelectedPrice(e.target.value)}
              disabled={disabled && allBlock}  
            />
          </div>
          <div style={{ marginTop: "43px", marginRight: "20px" }}>
            <Button
              className="save-changes-button"
              style={{ width: "167px" }}
              onClick={() => handleSaveChanges()}
              label="Save changes"
              disabled={allBlock}
            />
          </div>
        </div>
      </div>
      <Popup
        visible={isPopupVisible}
        userEmail={email}
        onClose={() => {
          setPopupVisible(false);
          fetchData();
        }}
        companyId={companyId}
      />
      <ToastContainer />
    </div>
  );
};
export default PlanService;
