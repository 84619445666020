import axios from "axios";

export default function SetInvoice(    
    companyId:string,
    status:number | string,
    invoiceId:number,
    type:string
){   
    const requestBody = {
        companyId,
        status,
        invoiceId,
        type,
      
    }       
    return axios.post(
        `${process.env.REACT_APP_BACKEND_LINK}/change/invoice/status`,  
        requestBody,      
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
}
