import axios from "axios";

export default function GenerateInvoice(
  paymentMethod: string,
  companyName: string,
  billingAddress: string,
  contactName: string,
  email: string,
  companyId: string,
  typeSubscription: string,
  unitPrice: number,
  total: number,
  numberLicenses: number,
  trueOrFalse: boolean,
  freeAccess: boolean
) {
  const requestBody = {
    paymentMethod,
    companyName,
    billingAddress,
    contactName,
    email,
    companyId,
    typeSubscription: typeSubscription.toLowerCase(),
    unitPrice: freeAccess ? 0 : unitPrice.toString(),
    total: freeAccess ? 0 : total.toString(),
    numberLicenses: numberLicenses.toString(),
    trueOrFalse,
  };
  console.log(requestBody);
  return axios
    .post(
      `${process.env.REACT_APP_BACKEND_LINK}/create/pdf/invoice`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error generating PDF:", error);
      throw error;
    });
}
