import axios from "axios";

export default function SetInvoice(    
    companyId:string,
    plan:string
){   
    const requestBody = {
        company: companyId,
        newPlan: plan,
      };            
    return axios
        .post(
          `${process.env.REACT_APP_BACKEND_LINK}/upgrade/plan`,
          requestBody,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("token"),
            },
          }
        )
}
