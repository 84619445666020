import React, { useEffect, useState, useRef } from "react";
import Sidebar from "./SideBar";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { Dropdown } from "primereact/dropdown";
import "./Video.css";
import { Checkbox } from "primereact/checkbox";
import "react-dropzone-uploader/dist/styles.css";
import DropzoneUploader from "react-dropzone-uploader";
import { ProgressSpinner } from "primereact/progressspinner";
import FormData from "form-data";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Card } from "primereact/card";
import { MultiSelect } from "primereact/multiselect";
import { OverlayPanel } from 'primereact/overlaypanel';
import "primereact/resources/themes/saga-blue/theme.css"; // подключение темы
import "primereact/resources/primereact.min.css"; // основные стили
import "primeicons/primeicons.css"; // иконки PrimeReact

function Video() {
  const [currentSelection, setCurrentSelection] = useState("360 Video");
  const [rowData, setRowData] = useState(null);
  const [videos, setVideos] = useState([]);
  const [showDialogAssign, setShowDialogAssign] = useState(false);
  const [showDialogUnassign, setShowDialogUnassign] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [publicity, setPublicity] = useState(0);
  const [isValidFile, setIsValidFile] = useState(true);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [responseUsed, setResponseUsed] = useState(null);
  const token = localStorage.getItem("token");

  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const overlayPanelRef = useRef(null);

  

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("currentSelection", currentSelection);
  }, [currentSelection]);


  const handleCloseDialogAssign = () => {
    setSelectedCompanies([]);
    setSelectedPlatform(null);
    setPublicity(1);
    setShowDialogAssign(false);
    setResponse(null);
    setResponseUsed(null);
  };

  const handleCloseDialogUnassign = () => {
    setSelectedCompanies([]);
    setSelectedPlatform(null);
    setPublicity(1);
    setShowDialogUnassign(false);
    setResponse(null);
    setResponseUsed(null);
  };

  const onUpload = (file) => {
    setIsLoading(true);
    console.log(file.file);
    // upload file logic here...
    setFile(file.file);
    setSelectedFile(file.file);
    setIsLoading(false);
  };

  const Preview = ({ meta, fileWithMeta: { remove } }) => {
    const handleRemove = (event) => {
      event.stopPropagation();
      setSelectedFile(null);
      setFile(null);  
      remove();
    };

    return (
      <div className="preview">
        <div className="flex flex-row gap-2 align-items-center">
          <i className="pi pi-file" style={{ fontSize: "1.2rem" }}></i>
          <p>{meta.name}</p>
          <button
            className="pi pi-trash "
            style={{
              border: "none",
              background: "none",
              marginLeft: "10px",
              fontSize: "1.2rem",
            }}
            onClick={handleRemove}
          ></button>
        </div>
      </div>
    );
  };

  const handleCloseDialogDelete = () => {
    setSelectedCompanies([]);
    setSelectedPlatform(null);
    setPublicity(1);
    setShowDialogDelete(false);
    setResponse(null);
    setResponseUsed(null);
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_LINK}/upload/poster/video/${rowData.id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success("Poster added successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              console.log("Company added successfully.");
              window.location.reload(true);
            }
          });

        setSelectedFile(null);
        setShowDialog(false);
      } catch (error) {
        console.error("Upload error:", error);
      }
    }
  };
  const fetchData = async () => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/videos/forAdmin`,
        config
      );
      setVideos(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePoster = (rowData) => {
    console.log("Poster clicked for row data:", rowData);
    setRowData(rowData);
    setShowDialog(true);
  };

  const handleAssign = async (rowData) => {
    console.log("Assign clicked for row data:", rowData);
    const fetchCompanies = axios.get(
      `${process.env.REACT_APP_BACKEND_LINK}/get/companies/byVideo/${rowData.id}`
    );
    console.log("videoId", rowData.id)
    console.log("fetchCompanies", fetchCompanies)
    
    const fetchCompanyList = axios.get(
      `${
        process.env.REACT_APP_BACKEND_LINK
      }/getCompanyList/${localStorage.getItem("id")}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const [companiesResponse, companyListResponse] = await Promise.all([
      fetchCompanies,
      fetchCompanyList,
    ]);

    if (
      companiesResponse.status === 200 &&
      companyListResponse.status === 200
    ) {
      const companies = companiesResponse.data.companies;
      console.log("companies", companies);  
      const companyList = companyListResponse.data.companyList;
      console.log("companyList", companyList);
      const result = companyList.map(({ id, companyName, userEmail }) => ({
        id,
        name: companyName,
        mail: userEmail,
        used: companies.some((company) => company.userEmail === userEmail)
          ? 1
          : 0,
      }));

      console.log(result);

      setResponse(result);
    }

    setRowData(rowData);
    setShowDialogAssign(true);
  };

  const handleUnassign = async (rowData) => {
    console.log("Assign clicked for row data:", rowData);
    try {
      const fetchCompanies = axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/companies/byVideo/${rowData.id}`
      );
      const fetchCompanyList = axios.get(
        `${
          process.env.REACT_APP_BACKEND_LINK
        }/getCompanyList/${localStorage.getItem("id")}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      const [companiesResponse, companyListResponse] = await Promise.all([
        fetchCompanies,
        fetchCompanyList,
      ]);

      if (
        companiesResponse.status === 200 &&
        companyListResponse.status === 200
      ) {
        const companies = companiesResponse.data.companies;
        console.log("companies", companies);  
        const companyList = companyListResponse.data.companyList;
        console.log("companyList", companyList);
        const result = companyList.map(({ id, companyName, userEmail }) => ({
          id,
          name: companyName,
          mail: userEmail,
          used: companies.some((company) => company.userEmail === userEmail)
            ? 1
            : 0,
        }));

        console.log(result);

        setResponse(result);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }


    setRowData(rowData);
    setShowDialogUnassign(true);
  };
  
  const handleDelete = async (rowData) => {
    console.log("Delete clicked for row data:", rowData);
    
    setRowData(rowData);
    setShowDialogDelete(true);
  };

  const handleEdit = (rowData) => {
    navigate("/editVideo", { state: { rowData } });
  };

  const handleSelectionChange = (selection) => {
    setCurrentSelection(selection);
  };

  
  const overlayPanelContent = (
    <div className="button-box">
      <button  className="button-panel" onClick={() => handleEdit(rowData)}>
        Edit
      </button>
      <button  className="button-panel" onClick={() => handlePoster(rowData)}>
        Add poster
      </button>
      <button  className="button-panel" onClick={() => handleAssign(rowData)}>
        Assign
      </button>
      <button  className="button-panel" onClick={() => handleUnassign(rowData)}>
        Unassign
      </button>      
      <button  className="button-panel" onClick={() => handleDelete(rowData)} style={{color:"red"}}>
        Delete
      </button>
    </div>
  );

  const handleButtonClick = (event, video) => {
    setRowData(video); // Сохраняем данные видео в состоянии
    overlayPanelRef.current.toggle(event); // Открываем OverlayPanel
  };

  useEffect(() => {
    if (currentSelection === "Companies") {
      navigate("/");
    } else if (currentSelection === "Plans") {
      navigate("/plans");
    }
  }, [currentSelection, navigate]);
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={rowData.image}
        alt={rowData.title}
        style={{ width: "100px", height: "auto" }}
      />
    );
  };

  

  return (
    <div style={{ display: "flex", margin: 0 }}>
      <div
        style={{
          width: "230px",
          height: "100vh",
          backgroundColor: "#f2f4f8",
          padding: "10px",
          margin: 0,
          zIndex: 1100,
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <Sidebar
          UIselection="360 Video"
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <div style={{ width: "80%", padding: "10px", marginLeft: "240px" }}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h2 id="title" style={{ margin: 0, whiteSpace: "nowrap" }}>
              {currentSelection}
            </h2>
            <div>
              <Button
                label="Add Video"
                icon="pi pi-plus"
                className="custom-button"
                onClick={() => {
                  navigate("/addVideo");
                }}
              />
            </div>
          </div>
        </div>
        <div className="video-grid">
          {videos.map((video) => (
            <div key={video.id} className="video-card">
              <div className="video-image">
                <img src={video.image} alt={video.title} />
                <div className="video-duration">{video.duration}</div>
              </div>
              <div className="video-info">
                <p className="category">{video.category}</p>
                <h3>{video.title}</h3>
              </div>
              <div className="button-con2">
                <Button
                  icon="pi pi-ellipsis-v"
                  className="dot-button1"
                  style={{
                    background: "none",
                    border: "none",
                    boxShadow: "none",
                    color: "rgba(46, 58, 75, 0.5)",
                  }}
                   onClick={(event) => handleButtonClick(event, video)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
      {/* Add Assign Dialog */}
      <Dialog
        visible={showDialogAssign}
        onHide={handleCloseDialogAssign}
        header={<div style={{ marginTop: "-25px",fontSize: "20px", }}>Assign 360 video</div>}
        modal
        style={{ width: "450px", height: "320px" }}
        closable={false}
        footer={
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
           
            <Button
              label="Cancel"
              className="cancel-button"
              style={{ marginTop: "-55px" }}
              onClick={() => {
                setSelectedCompanies([]);
                setSelectedPlatform(null); // Reset the selected platform
                setShowDialogAssign(false);
              }}
            />
             <Button
              label="Assign"
              className="upload-button"
              style={{ marginTop: "-55px" }}
              onClick={async () => {
                try {
                  for (const selectedCompany of selectedCompanies) {//debug
                    console.log("Selected company:", selectedCompany);
                    const companyId = response.find(
                      (option) => option.mail === selectedCompany.mail
                    ).id;

                    const requestBody = {
                      company: companyId,
                      video: rowData.id,
                      publicity: publicity,
                      assign:1
                    };

                    await axios
                      .post(
                        `${process.env.REACT_APP_BACKEND_LINK}/video/add/to/company`,
                        requestBody,
                        {
                          headers: {
                            Authorization: "Bearer " + token,
                          },
                        }
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          console.log("Training assigned successfully.");
                        }
                        toast.success("Video assigned successfully.", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      });
                  }

                  setShowDialogAssign(false);
                } catch (error) {
                  console.error("Assign error:", error);
                }
                
               window.location.reload(true);
              }}
              disabled={!selectedCompanies.length} // Disable the Assign button if no company or platform is selected
            />
          </div>
        }
      >
        <div style={{ width: "400px" }}>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "500",
              fontSize: "13px",
              color: "#2E3A4BB2"
            }}
          >
            {rowData && rowData.title}
          </p>
          <div style={{ marginTop: "25px" }}>
            <div style={{ width: "392px", marginTop: "5px" }}></div>
          </div>
          <div style={{ marginTop: "25px" }}>
            <label
              htmlFor="company-dropdown"
              style={{ fontWeight: "500", fontSize: "16px" }}
            >
              Company
            </label>
            <div style={{ width: "392px", marginTop: "5px" }}>
              <MultiSelect
                value={selectedCompanies}
                onChange={(e) => setSelectedCompanies(e.value)}
                options={response}
                optionLabel="mail"
                filter
                style={{
                  width: "100%",
                  height: "36px",
                  display: "flex",
                  alignItems: "center",
                }}
                placeholder="Select Companies"
                optionDisabled={(option) => option.used === 1}
              />
              <div
              style={{marginTop:"5px"}}>
              <label
              htmlFor="company-dropdown"
              style={{ fontWeight: "500", fontSize: "16px"  }}
            >
              Publicity
            </label>
            </div>
              <div style={{ marginTop: 5 }}>
                <Checkbox
                  checked={publicity}
                  onChange={(e) => setPublicity(e.checked)}
                  label="Publicity"
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: 40 }}></div>
        </div>
      </Dialog>

      <Dialog
        visible={showDialogUnassign}
        onHide={handleCloseDialogUnassign}
        header={<div style={{ marginTop: "-25px",fontSize: "20px", }}>Unassign 360 video</div>}
        modal
        style={{ width: "450px", height: "310px" }}
        closable={false}
        footer={
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >           
            <Button
              label="Cancel"
              className="cancel-button"
              style={{ marginTop: "-55px" }}
              onClick={() => {
                setSelectedCompanies([]);
                setSelectedPlatform(null); // Reset the selected platform
                setShowDialogUnassign(false);
              }}
            />
             <Button
              label="Unassign"
              className="upload-button"
              style={{ marginTop: "-55px", width: "100px"}}
              onClick={async () => {
                try {
                  for (const selectedCompany of selectedCompanies) {//debug
                    console.log("Selected company:", selectedCompany);
                    const companyId = response.find(
                      (option) => option.mail === selectedCompany.mail
                    ).id;

                    const requestBody = {
                      company: companyId,
                      video: rowData.id,                      
                    };

                    await axios
                      .post(
                        `${process.env.REACT_APP_BACKEND_LINK}/video/remove/from/company`,
                        requestBody,
                        {
                          headers: {
                            Authorization: "Bearer " + token,
                          },
                        }
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          console.log("Training assigned successfully.");
                        }
                        toast.success("Video assigned successfully.", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      });
                  }

                  setShowDialogAssign(false);
                } catch (error) {
                  console.error("Assign error:", error);
                }
                window.location.reload(true);
                setShowDialogUnassign(false);
              }}
              disabled={!selectedCompanies.length}
            />
          </div>
        }
      >
        <div style={{ width: "400px" }}>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "500",
              fontSize: "13px",
              color: "#2E3A4BB2"
            }}
          >
            {rowData && rowData.title}
          </p>
          <div style={{ marginTop: "25px" }}>
            <div style={{ width: "392px", marginTop: "5px" }}></div>
          </div>
          <div style={{ marginTop: "25px" }}>
            <label
              htmlFor="company-dropdown"
              style={{ fontWeight: "500", fontSize: "16px" }}
            >
              Select Companies
            </label>
            <div style={{ width: "392px", marginTop: "5px" }}>
              <MultiSelect
                value={selectedCompanies}
                onChange={(e) => setSelectedCompanies(e.value)}
                options={response}
                optionLabel="mail"
                filter
                style={{
                  width: "100%",
                  height: "36px",
                  display: "flex",
                  alignItems: "center",
                }}
                placeholder="Select Companies"
                optionDisabled={(option) => option.used === 0}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        header={<div style={{ marginTop: "-25px",fontSize: "20px", }}>Add poster</div>}
        modal
        closable={false}
        style={{ width: "400px" }}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            
            <Button
              label="Cancel"
              className="cancel-button"
              onClick={() => {
                setSelectedFile(null);
                setShowDialog(false);
              }}
            />
            <Button
              label="Upload"
              className="upload-button"
              onClick={handleFileUpload}
              disabled={!selectedFile}
            />
          </div>
        }
      >
        <p style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "500",
              fontSize: "13px",
              color: "#2E3A4BB2"
            }}>{rowData && rowData.title}</p>
            <div style={{display: "flex",gap:"5px", fontWeight: "500"}}>Upload poster<div style={{fontWeight: "300", fontSize:"13px", marginTop: "3px" }}>(JPG, PNG)</div></div>
        <div className="file-upload-section" style={{marginTop:"5px"}}>
          

          <DropzoneUploader
                  onChangeStatus={onUpload}
                  accept=".jpg,.png"
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                  inputContent={
                    <div>
                      <div
                        className="flex flex-raw gap-2"
                        style={{ marginLeft: "25px" }}
                      >
                        <i
                          className="pi pi-upload"
                          style={{
                            fontSize: "1.2rem",
                            color: "#005DFF",
                            fontWeight: "bold",
                          }}
                        ></i>
                        <div className="gidota">Select file</div>
                      </div>
                      <div className="gadost">
                        or drag & drop your file here
                      </div>
                    </div>
                  }
                  PreviewComponent={Preview}
                  styles={{
                    dropzone: {
                      minHeight: 50,
                      border: isValidFile
                        ? "2px dashed rgb(0,0,0,0.5)"
                        : "2px dashed red",
                      borderRadius: 5,
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 10,
                      width: "100%",
                      height: "80px",
                      transition: "none",
                    },
                    inputLabel: {
                      color: "#333",
                      fontSize: 14,
                    },
                    input: {
                      display: "none",
                    },
                    preview: {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 10,
                      position: "static",
                      transition: "none",
                    },
                    previewImage: {
                      maxHeight: 200,
                      maxWidth: "100%",
                    },
                    slider: {
                      display: "none",
                    },
                    dropzoneActive: {
                      borderStyle: "solid",
                      backgroundColor: "#eee",
                    },
                    previewContainer: {
                      transition: "none !important",
                    },
                  }}
                />
        </div>
      </Dialog>
      <Dialog
        visible={showDialogDelete}
        onHide={handleCloseDialogDelete}
        header={<div style={{ marginTop: "-25px", fontWeight: "600" }}>Delete Training</div>} // Add the marginTop to adjust the header position
        modal
        style={{ width: "450px", height: "260px" }}
        closable={false}
        footer={
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
           
            <Button
              label="Cancel"
              className="cancel-button"              
              onClick={() => {
                setSelectedCompanies([]);
                setSelectedPlatform(null); // Reset the selected platform
                setShowDialogDelete(false);
              }}
            />
             <Button
              label="Delete"
              className="upload-button"              
              onClick={async () => {
                try {
                  console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
                  console.log(rowData.id);
                  console.log(selectedPlatform);

                  const requestBody = {
                    id: rowData.id,
                  };

                  await axios
                    .post(
                      `${process.env.REACT_APP_BACKEND_LINK}/delete/metaenga/video/default`,
                      requestBody,
                      {
                        headers: {
                          Authorization: "Bearer " + token,
                        },
                      }
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        console.log("Training deleted successfully.");
                        toast.success("Training deleted successfully.", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      } else {
                        toast.error("Training not deleted.", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      }
                    });

                  window.location.reload(true);
                } catch (error) {
                  console.error("Assign error:", error);
                }

                setShowDialogDelete(false);
              }}
              //disabled={!selectedPlatform}
            />
          </div>
        }
      >
        <div style={{ width: "400px" }}>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            {rowData && rowData.name}
          </p>
          <div style={{ marginTop: "25px", marginLeft: "35px" }}>
            <label
              htmlFor="platform-dropdown"
              style={{ fontWeight: "500", fontSize: "16px" }}
            >
              Are tou sure you want to delete this training?
            </label>
            
          </div>
        </div>
      </Dialog>
      <OverlayPanel ref={overlayPanelRef}>
        {rowData && overlayPanelContent}
      </OverlayPanel>
    </div>
  );
}

export default Video;
