import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import Calc from "./CalcForm";
import {
  InputNumber,
  InputNumberValueChangeEvent,
} from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import "../Company.css";
import "primeicons/primeicons.css";
import DropdownEntity from "./DropdownEntity";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import generateInvoice from "./GenerateInvoice";
import getProgress from "./GetProgress";
import DownloadPDF from "./DownloadPDF";

interface PopupProps {
  visible: boolean;
  userEmail: string;
  onClose: () => void;
  companyId: string;
}

interface Plan {
  plan: string;
  type: string;
}

const Invoice: React.FC<PopupProps> = ({
  visible,
  userEmail,
  onClose,
  companyId,
}) => {
  const [paymentMethod, setPaymentMethod] = useState<string>("Standard");
  const [numberHeadsets, setNumberHeadsets] = useState<number>(1);
  const [headsetCost, setHeadsetCost] = useState<number>(50);
  const [selectedPlan, setSelectedPlan] = useState<string>("Monthly");
  const [planType, setPlanType] = useState<string>("Month");
  const [companyName, setCompanyName] = useState<string>("");
  const [bilingAddress, setBillingAddress] = useState<string>("");
  const [contactName, setContactName] = useState<string>("");
  const [email, setEmail] = useState<string>(userEmail);
  const [freeAccess, setFreeAccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [link, setLink] = useState<string>("");

  const plans: Plan[] = [
    { plan: "Yearly", type: "Year" },
    { plan: "Monthly", type: "Month" },
  ];
  const handleSelectionChange = (selected: Plan) => {
    console.log("Selected Plan:", selected);
    setSelectedPlan(selected.plan);
    setPlanType(selected.type);
  };

  return (
    <div>
      <Dialog
        visible={visible}
        onHide={onClose}
        closable={false}
        style={{ width: "440px", height: "870px" }}
        header={
          <div
            style={{ marginTop: "-25px", fontSize: "20px", fontWeight: "600" }}
          >
            Generate invoice
          </div>
        }
        footer={
          <div
            className="p-dialog-footer"
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button
              label="Close"
              className="generate-invoice-close"
              onClick={onClose}
            />
            <Button
              label={
                loading
                  ? "Generating..."
                  : success
                  ? "Download PDF"
                  : "Generate Invoice"
              }
              onClick={async () => {
                if (success) {
                  DownloadPDF(link);
                } else {
                  setLoading(true); 

                  try {
                    const response = await generateInvoice(
                      paymentMethod,
                      companyName,
                      bilingAddress,
                      contactName,
                      email,
                      companyId,
                      planType,
                      headsetCost,
                      Calc(numberHeadsets, headsetCost),
                      numberHeadsets,
                      false,
                      freeAccess
                    );
                    console.log(response);

                    try {
                      const progressResponse = await getProgress(response.data);
                      setLink(progressResponse.data.link);
                      setSuccess(true);
                    } catch (error) {
                      console.error("Error fetching progress:", error);
                    }
                  } catch (error) {
                    console.error("Error generating invoice:", error);
                  } finally {
                    setLoading(false); 
                  }
                }
              }}
              className={`generate-invoice-generate ${
                loading ? "p-button-loading" : ""
              }`}
              disabled={loading}
            />
          </div>
        }
        modal
        className="p-fluid"
      >
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Payment method
          </div>
          <div className="row" style={{ marginTop: "5px" }}>
            <RadioButton
              value="Standard"
              name="paymentMethod"
              checked={paymentMethod === "Standard"}
              onChange={(e: RadioButtonChangeEvent) =>
                setPaymentMethod(e.value as unknown as string)
              }
            />
            <label style={{ marginLeft: "-10px" }}>Standard</label>
            <RadioButton
              value="Enterprise"
              name="paymentMethod"
              checked={paymentMethod === "Enterprise"}
              onChange={(e: RadioButtonChangeEvent) => {
                setPaymentMethod(e.value as unknown as string);
                console.log(e.value);
              }}
            />
            <label style={{ marginLeft: "-10px" }}>Enterprise</label>
          </div>
        </div>
        <div className="row" style={{ marginTop: "5px" }}>
          <div className="column">
            <div style={{ fontWeight: "520", marginTop: "15px" }}>
              Number of headsets
            </div>
            <div className="row" style={{ marginTop: "5px" }}>
              <InputNumber
                value={numberHeadsets}
                onValueChange={(e: InputNumberValueChangeEvent) =>
                  setNumberHeadsets(e.value)
                }
                min={0}
                max={999}
                showButtons
                buttonLayout="horizontal"
                style={{ width: "130px" }}
                decrementButtonClassName="custom-decrement-button"
                incrementButtonClassName="custom-increment-button"
                decrementButtonIcon={<i className="pi pi-minus-circle"></i>}
                incrementButtonIcon={<i className="pi pi-plus-circle"></i>}
              />
              <div
                style={{
                  marginLeft: "-10px",
                  fontWeight: "400",
                  marginTop: "12px",
                  color: "#2E3A4BB2",
                  fontSize: "12px",
                }}
              >
                headsets
              </div>
            </div>
          </div>
          <div className="column">
            <div style={{ fontWeight: "520", marginTop: "15px" }}>Price</div>
            <div className="row" style={{ marginTop: "5px" }}>
              <InputNumber
                className="custom-input-style"
                value={headsetCost}
                onValueChange={(e: InputNumberValueChangeEvent) =>
                  setHeadsetCost(e.value)
                }
                min={0}
                max={999}
                style={{ width: "76px", borderWidth: "2px", height: "41px" }}
              />
              <div
                style={{
                  marginLeft: "-10px",
                  fontWeight: "400",
                  marginTop: "12px",
                  color: "#2E3A4BB2",
                  fontSize: "12px",
                }}
              >
                $ per headset
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Billing cycle
          </div>
          <div style={{ width: "360px" }}>
            <DropdownEntity
              className="custom-dropdown"
              initialEntity={{ plan: "Monthly", type: "Month" }}
              listOf={plans}
              optionLabel="plan"
              onChange={handleSelectionChange}
            />
          </div>
        </div>
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Company name
          </div>
          <InputText
            className="custom-inputText-style"
            style={{ width: "360px" }}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Billing address
          </div>
          <InputText
            className="custom-inputText-style"
            style={{ width: "360px" }}
            value={bilingAddress}
            onChange={(e) => setBillingAddress(e.target.value)}
          />
        </div>
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Contact Name
          </div>
          <InputText
            className="custom-inputText-style"
            style={{ width: "360px" }}
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
          />
        </div>
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>Email</div>
          <InputText
            className="custom-inputText-style"
            style={{ width: "360px" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div style={{ marginTop: "10px" }} className="row">
          <div
            style={{ fontWeight: "520", marginTop: "25px" }}
          >{`Total/${planType}`}</div>
          <div
            style={{
              fontWeight: "550",
              marginTop: "20px",
              marginLeft: "auto",
              fontSize: "18px",
            }}
          >{`$${Calc(numberHeadsets, headsetCost)}`}</div>
        </div>
        <div className="row">
          <div style={{ fontWeight: "520", marginTop: "25px" }}>
            Free access
          </div>
          <div
            style={{
              fontWeight: "550",
              marginTop: "25px",
              marginLeft: "auto",
              fontSize: "18px",
            }}
          >
            <InputSwitch
              className="custom-switch"
              checked={freeAccess}
              onChange={(e: InputSwitchChangeEvent) => setFreeAccess(e.value)}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Invoice;
