import axios from "axios";

export default function DownloadPDF(    
    link: string
){   
    let urlNew: string = process.env.REACT_APP_BACKEND_LINK + link;
    let fileName = link.split("/").pop();
    fetch(urlNew)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });

}
