import React, { useEffect, useState } from "react";
import Sidebar from "./SideBar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./AddTraining.css";
import "react-dropzone-uploader/dist/styles.css";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { useLocation } from "react-router-dom";
import "./EditTraining.css";

function EditTraining() {
  const [customName, setCustomName] = useState("");
  const [customDescription, setCustomDescription] = useState("");  
  const [Category, setCategory] = useState("");  
  const [currentSelection, setCurrentSelection] = useState("360 Video");
  const location = useLocation();
  const { rowData } = location.state;

  // Now you can access the rowData object
  console.log(rowData);

  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (currentSelection === "Companies") {
      navigate("/");
    } else if (currentSelection === "Plans") {
      navigate("/plans");
    }
  }, [currentSelection, navigate]);

  const fetchData = async () => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const responseVR = await axios
      .get(
        process.env.REACT_APP_BACKEND_LINK +
          "/get/exact/video/forAdmin/" +
          rowData.fullData.id,
        config
      )
      .catch((error) => {
        console.log(error);
        toast.error("Error Fetching Data");
      });
console.log(responseVR.data.data)
    setCustomName(responseVR.data.data[0].fullData.videoName);    
    setCustomDescription(responseVR.data.data[0].fullData.videoDescription);   
    // setShortDescription(responseVR.data.data.shortDescription);
    // setFeatures(responseVR.data.data.features);
    setCategory(responseVR.data.data[0].fullData.videoTheme);    
    // setObjectives(responseVR.data.data.objectives);
    // setAudience(responseVR.data.data.audience);
    // setModes(responseVR.data.data.modes);
    // setAssessment(responseVR.data.data.assessment);
    // setAvailable(responseVR.data.data.available);
    // setLanguage(responseVR.data.data.lang);
    // setnumberOfScenarios(responseVR.data.data.numberOfScenarios);
    // setArea(responseVR.data.data.area);
    // if (
    //   responseVR.data.data.createdAt === "" ||
    //   responseVR.data.data.createdAt === null
    // ) {
    //   setSelectedDate(null);
    // } else {
    //   // let preloadedDate = new Date(responseVR.data.data.createdAt);
    //   // setSelectedDate(preloadedDate);
    // }

    // setDuration(responseVR.data.data.duration);
    // console.log(responseVR.data.data);
  };

  useEffect(() => {
    localStorage.setItem("currentSelection", currentSelection);
  }, [currentSelection]);

  const handleSelectionChange = (selection) => {
    setCurrentSelection(selection);
  };
  const handleCustomNameChange = (e) => {
    setCustomName(e.target.value);
  };
  const handleCustomDescriptionChange = (e) => {
    setCustomDescription(e.target.value);
  };
 
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };
 
  
    const handleEdit = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const data = {
      videoName: customName,
      videoDescription: customDescription,
      videoTheme: Category,
    };
    const response = await axios
      .post(
        `${process.env.REACT_APP_BACKEND_LINK}/edit/exact/video/forAdmin/${rowData.id}`,
        data,
        config
      )
      .catch((error) => {
        console.log(error);
        toast.error("Error Editing Training");
      });
    if (response.status === 200) {
      toast.success("Training Edited Successfully");
      fetchData();
    }
  };
  return (
    <div style={{ display: "flex", margin: 0 }}>
      <div
        style={{
          width: "230px",
          height: "100vh",
          backgroundColor: "#f2f4f8",
          padding: "10px",
          margin: 0,
          zIndex: 1100,
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <Sidebar
          UIselection="VR Training"
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <div style={{ width: "80%", padding: "10px", marginLeft: "240px" }}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              icon="pi pi-arrow-left"
              className="dot-button"
              style={{
                background: "none",
                border: "none",
                boxShadow: "none",
                marginRight: "-15px",
                color: "rgba(46, 58, 75, 0.7)",
              }}
              onClick={() => {
                navigate("/360video", { state: { status: "360 Video" } });
              }}
            ></Button>
            <h2
              id="title"
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                marginLeft: "10px",
                color: "rgba(46, 58, 75, 0.7)",
              }}
            >
              {currentSelection} /
            </h2>
            <h2
              id="title"
              style={{ margin: 0, whiteSpace: "nowrap", marginLeft: "25px" }}
            >
              Edit
            </h2>
            
          </div>
          <div className="flex flex-column gap-2" style={{ flex: 1 }}>
            <div
              className="flex flex-row gap-2"
              style={{ marginTop: "35px", flex: 1 }}
            >
              <div
                className="flex flex-column gap-2"
                style={{ flex: 1, minWidth: "300px" }}
              >
                <label className="font-text" htmlFor="training title">
                  Video title
                </label>
                <InputText
                  id="training-title"
                  value={customName}
                  onChange={handleCustomNameChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <label
                  className="font-text"
                  htmlFor="description title"
                  style={{ marginTop: "52px" }}
                >
                  Subject
                </label>
                <InputText
                  id="training-title"
                  value={Category}
                  onChange={handleCategoryChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />          
                <div className="flex flex-row gap-2">
                  <div
                    className="flex flex-column gap-2"
                    style={{ flex: 1, minWidth: "300px" }}
                  >
                  
                  </div>
                  <div
                    className="flex flex-column gap-2"
                    style={{ flex: 1, minWidth: "300px" }}
                  >
                  </div>
                </div>
              </div>
              <div
                className="flex flex-column gap-2"
                style={{ flex: 1, minWidth: "300px" }}
              >
                <label className="font-text" htmlFor="description title">
                  Description
                </label>
                <InputTextarea
                  id="description title"
                  autoResize={false}
                  value={customDescription}
                  rows={7}
                  onChange={handleCustomDescriptionChange}
                  aria-describedby="description title-help"
                  style={{ width: "76%", resize: "none"}}
                />
              
               
               
               
                <div className="flex flex-row gap-2">
                  <div
                    className="flex flex-column gap-2"
                    style={{ flex: 1, minWidth: "300px" }}
                  >
                    
                  </div>
                  <div
                    className="flex flex-column gap-2"
                    style={{ flex: 1, minWidth: "300px" }}
                  >
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex flex-row gap-2"
              style={{ marginTop: "35px", justifyContent: "center" }}
            >
              <Button
                label="Back"
                className="custom-buttonq"
                style={{ padding: "4px 8px" }}
                onClick={() => {
                  navigate("/360video", { state: { status: "360 Video" } });
                }}
              />
              <Button
                label="Save changes"
                className="custom-button"
                style={{ padding: "4px 8px" }}
                onClick={handleEdit}
              />
              
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default EditTraining;
