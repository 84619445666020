import axios from "axios";

export default function GetInvoices(    
    companyId: string
){   
    return axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/invoices/by/company/${companyId}`,        
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
}
