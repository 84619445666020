import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Message } from "primereact/message";
import axios from "axios";
import styles from "../styles/login.module.css";
import { ToastContainer, toast } from "react-toastify";
import "../App.css";

function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailWrong, setIsEmailWrong] = useState(false);
  const [isPassordWrong, setPasswordWrong] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
   
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK + "/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.status === "authorized") {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("id", response.data.id);
          setIsEmailWrong(false);
          setPasswordWrong(false);
          setIsAuthenticated(true);
        } else {
          setIsError(true);
          setPasswordWrong(false);
          setIsEmailWrong(false);
          toast.error("Internal server error");
        }
        console.log(response);
      })
      .catch((e) => {
        console.log(e.response.status);
        if (e.response.data.status == "wrong email") {
          setIsEmailWrong(true);
          // setEmail("");
          setPassword("");
        } else if (e.response.data.status == "wrong pass") {
          setPasswordWrong(true);
          setPassword("");
        } else {
          toast.error("Internal server error");
          setIsError(true);
        }
      });

    // Clear the form
  };
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className={styles.screen}>
      <div className={styles.form}>
        <div
          style={{
            textAlign: "center",
            marginBottom: "15px",
            fontWeight: "500",
            fontSize: "20px",
            color: "black",
          }}
        >
          Login
        </div>
        <div className={styles.input}>
          <form onSubmit={handleSubmit}>
            <div className={styles.input_field} style={{padding:"5px 5px 0px 5px"}}>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-user" />
                </span>
                <InputText
                  placeholder="example@gmail.com"
                  type="text"
                  value={email}
                  className={
                    isEmailWrong ? "input-login-error" : "input-login-default"
                  }
                  onChange={(e) => {
                    setIsEmailWrong(false);
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <Message
                className={`${
                  isEmailWrong
                    ? "custom-message-error "
                    : "custom-message-error-hidden"
                }`}
                icon
                severity="error"
                text="Invalid email"
              />
            </div>
            <div className={styles.input_field} style={{padding:"0px 5px 0px 5px"}}>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-key" />
                </span>
                <InputText
                  placeholder="Password"
                  type="password"
                  value={password}
                  className={
                    isPassordWrong ? "input-login-error" : "input-login-default"
                  }
                  onChange={(e) => {
                    setPasswordWrong(false);
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <Message
                className={`${
                  isPassordWrong
                    ? "custom-message-error "
                    : "custom-message-error-hidden"
                }`}
                icon
                severity="error"
                text="Invalid password"
              />
            </div>
            <div className={styles.submit}>
              <Button type="submit" className="custom-login-button">
                <div style={{ paddingBottom: "3px" }}>Login</div>
              </Button>
            </div>
          </form>
        </div>
        {/* <div className={styles.submit}>
          {isEmailWrong ? (
            <Message
              className="custom-message-error"
              icon
              severity="error"
              text="Invalid email"
            />
          ) : null}
          {isPassordWrong ? (
            <Message
              className="custom-message-error"
              icon
              severity="error"
              text="Invalid password"
            />
          ) : null}
          {isError ? (
            <Message
              className="custom-message-error"
              icon
              severity="error"
              text="Something went wrong"
            />
          ) : null}
        </div> */}
      </div>
      <ToastContainer />
    </div>
  );
}
export default LoginForm;
