import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";


export default function TableComponent({ data, onDelete }) {
  return (
    <DataTable value={data} header={null} className="no-header">
      <Column className="ids" field="id" />
      <Column 
      className="names" body={(rowData) => <div className="recordSettings">{rowData.name}</div>}
      />
      <Column className="actions" body={onDelete} />
    </DataTable>
  );
}

