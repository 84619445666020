import React, { useEffect, useRef, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "./SideBar";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "primereact/button";
import axios from "axios";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./Company.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Activity from "./../images/ic_activity.png";
import Users from "./../images/ic_users.png";
import Devices from "./../images/ic_devices.png";
import Daily from "./../images/ic_daily act users.png";
import Monthly from "./../images/ic_monthly act.png";
import Exclusive from "./../images/Excl TR.png";
import invoice from "./../images/invoicegener.png";
import download from "./../images/downlaod.png";
import trImage from "./../images/tr_image.svg";
import { Calendar } from "primereact/calendar";
import { Chips } from "primereact/chips";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { FileUpload } from 'primereact/fileupload';
import Modal from 'react-modal';        
import PlanService from "./PlanService/PlanService";

const token = localStorage.getItem("token");

function CompanyProfile() {
  const [number, setNumber] = useState(null);
  const [link, setLink] = useState(null);
  const [emails, setEmails] = useState([]);
  const [mailToAdd, setMailToAdd] = useState("");
  const [currentSelection, setCurrentSelection] = useState("Companies");
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowData, setRowData] = useState({});
  const [paymentType, setPaymentType] = useState("Monthly");
  const [paymentTypeS, setPaymentTypeS] = useState("Monthly");
  const [isInvoicePayment, setIsInvoicePayment] = useState();
  const [trainings, setTrainings] = useState([]);
  const [trainingsLength, setTrainingsLength] = useState(0);
  const [videos, setVideos] = useState([]);
  const [videosLength, setVideosLength] = useState(0);
  const [devices, setDevices] = useState([]);
  const [devicesLength, setDevicesLength] = useState(0);
  const [users, setUsers] = useState([]);
  const [usersLength, setUsersLength] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [addUserDialogVisible, setAddUserDialogVisible] = useState(false);
  const [lastActivity, setLastActivity] = useState("n/a");
  const [lastActivityYear, setLastActivityYear] = useState("n/a");
  const [statsDevices, setStatsDevices] = useState("n/a");
  const [statsUsers, setStatsUsers] = useState("n/a");
  const [statsExclusive, setStatsExclusive] = useState("n/a");
  const [selectedDate, setSelectedDate] = useState(null);
  const [statsMonthlyActiveUsers, setStatsMonthlyActiveUsers] = useState("n/a");
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [percentChangeMonthlyActiveUsers, setPercentChangeMonthlyActiveUsers] =
    useState("n/a");
  const [statsVideos, setStatsVideos] = useState("n/a");
  const [companyName, setCompanyName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [unitPrice, setUnitPrice] = useState(99);
  const [invoiceType, setInvoiceType] = useState("/month");
  const [sendToTheClient, setSendToTheClient] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [chooseInvoice, setChooseInvoice] = useState(null);
  const [licenses, setLicenses] = useState(0);
  const [isBanner, setIsBanner] = useState(false);
  const [bannerLink, setBannerLink] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("")

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };
  
  let { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (currentSelection === "VR Training") {
      navigate("/");
    } else if (currentSelection === "Plans") {
      navigate("/plans");
    }
  }, [currentSelection, navigate]);

  const fetchData = async () => {
    console.log("fetching dataAAAAAAAAAAAAAa");
    try {
      let data = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_LINK
        }/getOneCompany/${localStorage.getItem("id")}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setRowData(data.data.company);
      setLicenses(data.data.company.payedLicense);
      console.log(data.data.company.payedLicense);
      setCompanyName(data.data.company.companyName);
      setIsInvoicePayment(data.data.company.invoice);

      setContactName(data.data.company.contact);
      setEmail(data.data.company.userEmail);
      console.log(data.data.company.invoice);
      if (data.data.company.invoice == true) {
        setPaymentTypeS("Invoice");
        setIsInvoicePayment(true);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error fetching company profile.");
    }
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/company/payment/status/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if(response.data.data == "Stripe"){
        setPaymentMethod("Stripe")
      }else if(response.data.data == "No payment"){
        setPaymentMethod("No payment")
      }else if(response.data.data == "Invoice"){
        setPaymentMethod("Invoice")
      }else if(response.data.data == "No stripe"){
        setPaymentMethod("No stripe")
      }
    } catch (error) {
      console.log(error);
    }
    try {
      let trainings = await axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/all/trainings/by/company/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setTrainings(trainings.data.data);
      setTrainingsLength(trainings.data.data.length);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching trainings.");
    }
    try {
     
      let videos = await axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/video/metaenga/byOwner/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("AAAAAAAAAAAAAAA");
      console.log(videos);
      setVideos(videos.data.metaenga);
      setVideosLength(videos.data.metaenga.length);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching trainings.");
    }
    try {
      let devices = await axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/getCompanyDevices/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setDevices(devices.data.data);
      setDevicesLength(devices.data.data.length);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching devices.");
    }
    try {
      let users = await axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/getCompanyUsers/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setUsers(users.data.data);
      setUsersLength(users.data.data.length);
      setFilteredUsers(users.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching users.");
    }
    try {
      let statistic = await axios.get(
        `${process.env.REACT_APP_STATISTICS_LINK}/get/company/profile/page/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      let time = new Date(statistic.data.data.lastActivity);
      let month = time.toLocaleString("en-US", { month: "long" });
      let day = time.getDate();
      let year = time.getFullYear();
      setLastActivity(month + " " + day);
      setLastActivityYear(year);
      setStatsDevices(statistic.data.data.devices);
      setStatsUsers(statistic.data.data.users);
      setStatsExclusive(statistic.data.data.exclusive);
      setStatsMonthlyActiveUsers(statistic.data.data.monthlyActiveUsers);
      setPercentChangeMonthlyActiveUsers(
        statistic.data.data.percentChangeMonthlyActiveUsers
      );
      setStatsVideos(statistic.data.data.videos);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching statistics.");
    }
    try {
      let banner = await axios.post(
        `${process.env.REACT_APP_BACKEND_LINK}/banner/check/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(banner);
      if (banner.data.banner != null) {
        setIsBanner(true);
        console.log("Banner is true");
        try {
          let banner = await axios.get(
            `${process.env.REACT_APP_BACKEND_LINK}/get/company/banner/${id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          console.log(banner);
          setBannerLink(banner.data.url);
          console.log(bannerLink);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
    try {
      let invoices = await axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/invoices/by/company/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      let objArr = [];
      for (let i = 0; i < invoices.data.data.length; i++) {
        objArr.push({
          label: invoices.data.data[i].invoice_id,
          value: invoices.data.data[i].invoice_id,
        });
      }
      setInvoiceList(objArr);
      for (let i = 0; i < invoices.data.data.length; i++) {
        if (invoices.data.data[i].status == 1) {
          console.log(invoices.data.data[i].invoice_id);
          setChooseInvoice(invoices.data.data[i].invoice_id);
          try {
            let link = await axios.get(
              `${process.env.REACT_APP_BACKEND_LINK}/get/progress/pdf/${invoices.data.data[i].invoice_id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            console.log(link.data);
            setLink(link.data.link);
          } catch (error) {
            console.log(error);
          }
        }
      }
      console.log(invoiceList);
    } catch (error) {
      console.log(error);
      // toast.error("Error fetching data.");
    }
    
  };

  const planOptions = [
    { label: "Free", value: "Free" },
    { label: "Standard", value: "Standart" },
    { label: "Premium", value: "Premium" },
    { label: "Enterprise", value: "Enterprise" },
  ];
  const billing = [
    {
      label: (
        <div className="row">
          <div>Monthly</div>
          <div style={{ color: "rgba(46, 58, 75, 0.7)", marginLeft: "70px" }}>
            $99 per headset/month
          </div>
        </div>
      ),
      value: "/month",
    },
    {
      label: (
        <div className="row">
          <div>Annually</div>
          <div style={{ color: "rgba(46, 58, 75, 0.7)", marginLeft: "70px" }}>
            $1080 per headset/year
          </div>
        </div>
      ),
      value: "/year",
    },
  ];

  useEffect(() => {
    localStorage.setItem("currentSelection", currentSelection);
  }, [currentSelection]);

  const handleSelectionChange = (selection) => {
    setCurrentSelection(selection);
  };
  const rowNumberTemplate = (data, props) => {
    return props.rowIndex + 1;
  };
  const onGlobalFilterChange = (data) => {
    const lowercasedData = data.toLowerCase();

    const filteredData = users.filter((user) => {
      return (
        user.name.toLowerCase().includes(lowercasedData) ||
        user.email.toLowerCase().includes(lowercasedData) ||
        user.role.toLowerCase().includes(lowercasedData) ||
        user.status.toLowerCase().includes(lowercasedData)
      );
    });

    setFilteredUsers(filteredData);
    setGlobalFilter(data);
  };
  const filteredUsersMemo = useMemo(() => {
    return filteredUsers;
  }, [filteredUsers]);

  const roles = [
    { label: "Owner", value: "OWNER" },
    { label: "Admin", value: "ADMIN" },
    { label: "Enhanced", value: "ENHANCED" },
  ];

  const statuses = [
    { label: "Active", value: "ACTIVE" },
    { label: "Deactivated", value: "DEACTIVATED" },
  ];

  const getRowRole = (role) => {
    if (role == "OWNER") {
      return "Owner";
    } else if (role == "ADMIN") {
      return "Admin";
    } else if (role == "ENHANCED") {
      return "Enhanced";
    }
  };
  const handleDelete = async () => {
    console.log("Delete clicked for row data:");
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const requestBody = {
      admin: id,
      companyID: rowData.id,
    };
    const response = await axios
      .post(
        process.env.REACT_APP_BACKEND_LINK + "/deleteCompany",
        requestBody,
        config
      )
      .catch((error) => {
        console.log(error);
        toast.error("Error deleting company profile.");
      });
    if (response.status === 200) {
      toast.success("Company profile deleted successfully.");
      setIsDeleteDialogVisible(false);
      navigate("/", { state: { status: "Companies" } });
    }
  };
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
  };
  const setSwitchValue = (value) => {
    setIsInvoicePayment(value);
  };
  const onHide = () => {
    setVisible(false);
  };
  const handleAddUser = async () => {
    if (!emails.length) return;

    for (const email of emails) {
      try {
        let requestBody = {
          companyName: rowData.companyName,
          creater: users[0].id,
          role: "ENHANCED",
          userEmail: email,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_LINK}/register/user`,
          requestBody,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        if (response.status === 200) {
          console.log(`Invite sent successfully to ${email}.`);
          toast.success(`Invite sent successfully to ${email}.`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (error) {
        console.error(`Error inviting ${email}:`, error.message);
        toast.error(`Error inviting ${email}: ${error.message}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    setEmails([]);
    setMailToAdd("");
    setAddUserDialogVisible(false);
  };

  const generateInvoice = async () => {
    try {
      setLoading(true);
      const requestBody = {
        companyName: companyName,
        billingAddress: billingAddress,
        contactName: contactName,
        email: rowData.userEmail,
        companyId: rowData.id,
        typeSubscription: invoiceType,
        unitPrice: unitPrice.toString(),
        total: totalCost.toString(),
        numberLicenses: value.toString(),
        trueOrFalse: sendToTheClient,
      };
      console.log(requestBody);
      let pdf = await axios.post(
        `${process.env.REACT_APP_BACKEND_LINK}/create/pdf/invoice`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(pdf.data.data);
      let number = pdf.data.data;
      try {
        let link = await axios.get(
          `${process.env.REACT_APP_BACKEND_LINK}/get/progress/pdf/${number}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log(link.data);
        setLink(link.data.link);

        setSuccess(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const downloadPDF = async () => {
    if (link) {
      // Create an anchor element to trigger the download
      let urlNew;
      urlNew = process.env.REACT_APP_BACKEND_LINK + link;
      let fileName = link.split("/").pop();
      fetch(urlNew)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName; // You can set the desired file name here
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
    }
  };

  return (
    <div style={{ display: "flex", margin: 0 }}>
      <div
        style={{
          width: "230px",
          height: "100vh",
          backgroundColor: "#f2f4f8",
          padding: "10px",
          margin: 0,
          zIndex: 900,
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <Sidebar
          UIselection="Companies"
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <div style={{ width: "80%", padding: "10px", marginLeft: "240px" }}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              icon="pi pi-arrow-left"
              className="dot-button"
              style={{
                background: "none",
                border: "none",
                boxShadow: "none",
                marginRight: "-15px",
                color: "rgba(46, 58, 75, 0.7)",
              }}
              onClick={() => {
                navigate("/", { state: { status: "Companies" } });
              }}
            ></Button>
            <h2
              id="title"
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                marginLeft: "18px",
                color: "rgba(46, 58, 75, 0.7)",
              }}
            >
              {currentSelection} /
            </h2>
            <h2
              id="title"
              style={{ margin: 0, whiteSpace: "nowrap", marginLeft: "41px" }}
            >
              {rowData.companyName}
            </h2>
            <div
              className="row"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {/* <Button className="button-ablec">
                <div className="button-text">Change to inactive</div>
              </Button> */}
              <Button
                className="button-able"
                onClick={() => {
                  setIsDeleteDialogVisible(true);
                }}
              >
                <div className="button-text">Delete profile</div>
              </Button>
            </div>
          </div>
          <div class="column" style={{ marginTop: "20px" }}>
            <div class="row">
              <div
                style={{
                  border: "1px solid #c0c0c0",
                  height: "104px",
                  width: "100%",
                  borderRadius: "6px",
                  minWidth: "330px",
                  position: "relative",
                }}
              >
                <img
                  src={Activity}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    width: "36px",
                  }}
                />
                <div
                  class="column"
                  style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                >
                  Last activity
                </div>
                <div
                  class="column"
                  style={{
                    padding: "10px 10px 0px 20px",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  <div class="row">
                    <div>{lastActivity},</div>
                    <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                      {lastActivityYear}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #c0c0c0",
                  height: "104px",
                  width: "100%",
                  borderRadius: "6px",
                  minWidth: "330px",
                  position: "relative",
                }}
              >
                <img
                  src={Users}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    width: "36px",
                  }}
                />
                <div
                  class="column"
                  style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                >
                  Total users
                </div>
                <div
                  class="column"
                  style={{
                    padding: "10px 10px 0px 20px",
                    fontSize: "25px",
                    fontWeight: "600",
                  }}
                >
                  <div class="row">
                    <div>{statsUsers}</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #c0c0c0",
                  height: "104px",
                  width: "100%",
                  borderRadius: "6px",
                  minWidth: "330px",
                  position: "relative",
                }}
              >
                <img
                  src={Devices}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    width: "36px",
                  }}
                />
                <div
                  class="column"
                  style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                >
                  Total devices
                </div>
                <div
                  class="column"
                  style={{
                    padding: "10px 10px 0px 20px",
                    fontSize: "25px",
                    fontWeight: "600",
                  }}
                >
                  <div class="row">
                    <div>{statsDevices}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style={{ marginTop: "10px" }}>
              <div
                style={{
                  border: "1px solid #c0c0c0",
                  height: "104px",
                  width: "100%",
                  borderRadius: "6px",
                  minWidth: "330px",
                  position: "relative",
                }}
              >
                <img
                  src={Daily}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    width: "36px",
                  }}
                />
                <div
                  class="column"
                  style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                >
                  Uploaded 360 Videos
                </div>
                <div
                  class="column"
                  style={{
                    padding: "10px 10px 0px 20px",
                    fontSize: "25px",
                    fontWeight: "600",
                  }}
                >
                  <div class="row">
                    <div>{statsVideos}</div>
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      gap: "5px",
                    }}
                  >
                    {/* <div style={{ color: dailyActiveUsersPercent > 0 ? '#1FB155' : dailyActiveUsersPercent < 0 ? '#E82F2F' : 'black' }}>
  {dailyActiveUsersPercent}%
</div> */}

                    {/* <div style={{color:"rgba(46, 58, 75, 0.7)"}}>in the last 24 hours</div> */}
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #c0c0c0",
                  height: "104px",
                  width: "100%",
                  borderRadius: "6px",
                  minWidth: "330px",
                  position: "relative",
                }}
              >
                <img
                  src={Monthly}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    width: "36px",
                  }}
                />
                <div
                  class="column"
                  style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                >
                  Monthy active users
                </div>
                <div
                  class="column"
                  style={{
                    padding: "10px 10px 0px 20px",
                    fontSize: "25px",
                    fontWeight: "600",
                  }}
                >
                  <div class="row">
                    <div>{statsMonthlyActiveUsers}</div>
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      gap: "5px",
                    }}
                  >
                    <div
                      style={{
                        color:
                          percentChangeMonthlyActiveUsers > 0
                            ? "#1FB155"
                            : percentChangeMonthlyActiveUsers < 0
                            ? "#E82F2F"
                            : "black",
                      }}
                    >
                      {percentChangeMonthlyActiveUsers}%
                    </div>
                    <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                      the last 30 days
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #c0c0c0",
                  height: "104px",
                  width: "100%",
                  borderRadius: "6px",
                  minWidth: "330px",
                  position: "relative",
                }}
              >
                <img
                  src={Exclusive}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    width: "36px",
                  }}
                />
                <div
                  class="column"
                  style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                >
                  Exclusive trainings
                </div>
                <div
                  class="column"
                  style={{
                    padding: "10px 10px 0px 20px",
                    fontSize: "25px",
                    fontWeight: "600",
                  }}
                >
                  <div class="row">
                    <div>{statsExclusive}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Accordion multiple style={{ marginTop: "10px" }}>
            <AccordionTab
              header={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "flex-start",
                  }}
                >
                  Company profile
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 8px 1px",
                      gap: "10px",
                      background: "#DDF9E0",
                      borderRadius: "8px",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      border: "1px solid #1FB155",
                      fontWeight: 500,
                      fontSize: "12px",
                      lineHeight: "15px",
                      letterSpacing: "0.035em",
                      color: "#1FB155",
                    }}
                  >
                    {rowData.plan}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 8px 1px",
                      gap: "10px",
                      background: "#F2F4F8",
                      borderRadius: "8px",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      border: "1px solid #38475c",
                      lineHeight: "15px",
                      letterSpacing: "0.035em",
                      color: "#2E3A4B",
                    }}
                  >
                    {paymentTypeS}
                  </div>
                </div>
              }
            >
              <div className="column">
                <div className="row">
                  <div className="column">
                    <div className="column">
                      <div>Company name</div>
                      <InputText
                        style={{ width: "100%" }}
                        value={companyName}
                      />
                    </div>
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="column">
                        <div>Description</div>
                        <InputTextarea
                          id="description title"
                          autoResize
                          rows={6}
                          style={{
                            width: "100%",
                            height: "134px",
                            maxHeight: "134px",
                          }}
                          aria-describedby="description title-help"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column" style={{ width: "100%" }}>
                    <div className="row">
                      <div className="column">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          Payment method
                          <InputSwitch
                            checked={isInvoicePayment}
                            disabled={!chooseInvoice && !isInvoicePayment}
                            onChange={async (e) => {
                              setSwitchValue(e.value);
                              const requestBody = {
                                companyId: rowData.id,
                                status: e.value,
                                invoiceId: chooseInvoice,
                              };
                              console.log("Request body:", requestBody);
                              const token = localStorage.getItem("token");
                              let inv = await axios.post(
                                `${process.env.REACT_APP_BACKEND_LINK}/change/invoice/status`,
                                requestBody,
                                {
                                  headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                      "token"
                                    )}`,
                                  },
                                }
                              );
                              console.log(inv.data.data);
                              setChooseInvoice(null);
                              toast.success(
                                "Invoice status changed successfully"
                              );
                              fetchData();
                            }}
                          />
                        </div>
                        {/* <div
                          style={{
                            border: "1px solid #d1d5db",
                            height: "48px",
                            width: "100%",
                            borderRadius: "6px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "9px 12px",
                            }}
                          >
                            Invoice{" "}
                            
                          </div>
                        </div> */}
                        <Dropdown
                          style={{ width: "100%", minWidth: "167.59px" }}
                          placeholder={
                            chooseInvoice ? chooseInvoice : "Choose invoice ID"
                          }
                          disabled={isInvoicePayment}
                          options={invoiceList}
                          onChange={(e) => {
                            setChooseInvoice(e.value);
                          }}
                          filter
                          filterBy="value"
                          filterMode="startsWith"
                        />
                      </div>
                      <div className="column">
                        <div>Invoice expiry date</div>
                        <Calendar
                          id="date-input"
                          value={selectedDate}
                          onChange={handleDateChange}
                          className="custom-calendar"
                          appendTo={document.body}
                          showIcon={true} // Optional: Show a calendar icon for easier date selection
                          style={{ width: "100%" }}
                          disabled={!isInvoicePayment}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="column">
                        <div>Licenses purchased</div>
                        <div className="row">
                          <InputText
                            keyfilter="pint"
                            style={{ width: "50%" }}
                            value={licenses}
                            onChange={(e) => setLicenses(e.target.value)}
                            disabled={!isInvoicePayment}
                          />
                          <Button
                            style={{
                              height: "44px",
                              width: "50%",

                              background: "none",
                              color: "#005DFF",
                              border: "none",
                            }}
                            className="button-able"
                            onClick={async () => {
                              const requestBody = {
                                companyId: rowData.id,
                                number: licenses,
                              };
                              console.log("Request body:", requestBody);
                              const token = localStorage.getItem("token");
                              let inv = await axios.post(
                                `${process.env.REACT_APP_BACKEND_LINK}/change/licenses`,
                                requestBody,
                                {
                                  headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                      "token"
                                    )}`,
                                  },
                                }
                              );
                              console.log(inv.data.data);
                              toast.success(
                                "Number of licenses changed successfully"
                              );
                              fetchData();
                            }}
                          >
                            <div>Save changes</div>
                          </Button>
                        </div>
                      </div>
                      <div className="column">
                        <div>Licenses allocated</div>
                        <InputText
                          keyfilter="pint"
                          style={{ width: "100%" }}
                          value={rowData.licenses}
                          disabled="true"
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="column">
                        <div>Change plan</div>
                        <Dropdown
                          style={{ width: "100%", minWidth: "167.59px" }}
                          placeholder={rowData.plan}
                          options={planOptions}
                          onChange={(e) => {
                            const requestBody = {
                              company: rowData.id,
                              newPlan: e.value,
                            };
                            console.log("Request body:", requestBody);
                            axios
                              .post(
                                `${process.env.REACT_APP_BACKEND_LINK}/upgrade/plan`,
                                requestBody,
                                {
                                  headers: {
                                    Authorization:
                                      "Bearer " + localStorage.getItem("token"),
                                  },
                                }
                              )
                              .then((response) => {
                                if (response.status === 200) {
                                  console.log(
                                    "Training assigned successfully."
                                  );
                                }
                                toast.success("Plan changed successfully", {
                                  position: toast.POSITION.BOTTOM_RIGHT,
                                });
                                fetchData();
                              });

                            console.log("Plan updated for:", rowData);
                            console.log("Selected Plan:", e.value);
                          }}
                          // disabled={isInvoicePayment}
                        />
                      </div>
                      <div className="column">
                        <div className="row">
                          <Button
                            className="generate-invoice"
                            style={{ height: "44px", marginTop: "24px" }}
                            // disabled={!isInvoicePayment}
                            onClick={() => {
                              setVisible(true);
                            }}
                          >
                            <img
                              src={invoice}
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="button-text">Generate invoice</div>
                          </Button>
                          <Button
                            style={{
                              height: "44px",
                              marginTop: "24px",
                              width: "50px",
                              position: "relative",
                              background: "#FFFFFF",
                              color: "#005DFF",
                              border: "2px solid #005DFF",
                            }}
                            onClick={downloadPDF}
                            disabled={!isInvoicePayment}
                          >
                            <img
                              src={download}
                              style={{
                                width: "20x",
                                height: "20px",
                                marginLeft: "-7px",
                              }}
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab
              header={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "flex-start",
                  }}
                >
                  Payment method     
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 8px 1px",
                      gap: "10px",
                      background: "#DDF9E0",
                      borderRadius: "8px",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      border: "1px solid #1FB155",
                      fontWeight: 500,
                      fontSize: "12px",
                      lineHeight: "15px",
                      letterSpacing: "0.035em",
                      color: "#1FB155",
                    }}
                  >
                    {paymentMethod}
                  </div>   
                </div>
              }
            >
              <PlanService
              email={email} 
              companyId={id}
              onInvoiceChange={fetchData}
              onPlanChange={fetchData}
              onSaveChanges={fetchData}
              />
            </AccordionTab>
            <AccordionTab
              header=<div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <div>Trainings</div>
                <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>/</div>
                <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                  {trainingsLength}
                </div>
              </div>
            >
            
            {!isBanner ? (
   <div style={{ width: "100%", marginBottom: "7px", marginTop: "-10px", display:"flex" }}>
   <img
     src={trImage}
     style={{
       width: "50px",
       height: "50px",          
       
       borderRadius: "4px 4px 4px 4px",
       border: "0.5px solid #2E3A4B4D",         
     }}      
   />
    <FileUpload
   mode="basic"
   name="file" // this must match the `upload.single('file')` in your backend
   url={`${process.env.REACT_APP_BACKEND_LINK}/upload/banner/${rowData.id}`} // this must match the route in your backend
   accept="image/*"
   style={{ width: "100%",   background:"none"}}
   maxFileSize={5000000} // Max file size (5MB here)
   chooseLabel={ <div style={{fontWeight:"500", marginLeft:"-10px" }}>Add brand logo</div>}
   onUpload={() => {
     toast.success("Banner uploaded successfully");
     fetchData();
   }}
   onError={() => {
     toast.error("Error uploading banner");
   }}
 />
 </div>
  ) : (
    <div style={{ width: "100%", marginBottom: "7px", marginTop: "-10px", display:"flex" }}>
     <button
        onClick={openModal} // Open modal on button click
        className="image-button" // Add class for button styling
      >
        <img
          src={bannerLink}
          alt="Thumbnail" // Add alt for accessibility
          className="hover-border-image" // Add class for hover effect
        />
      </button>
       <FileUpload
      mode="basic"
      name="file" // this must match the `upload.single('file')` in your backend
      url={`${process.env.REACT_APP_BACKEND_LINK}/upload/banner/${rowData.id}`} // this must match the route in your backend
      accept="image/*"
      style={{ width: "100%",  background:"none"}}
      maxFileSize={5000000} // Max file size (5MB here)
      chooseLabel={ <div style={{fontWeight:"500" , marginLeft:"-10px" }}>Update brand logo</div>}
      onUpload={() => {
        toast.success("Banner uploaded successfully");
        fetchData();
      }}
      onError={() => {
        toast.error("Error uploading banner");
      }}
    />
      <Modal       
        isOpen={modalIsOpen}       
        onRequestClose={closeModal}
        contentLabel="Full Size Image"         
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 1000, // Ensure the overlay is above other content
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '80%', // Adjust as necessary
            maxWidth: '600px', // Set a max width if needed
            maxHeight: '80%', // Set a max height if needed
            zIndex: 1100, // Set a high zIndex for the modal content to overlap sidebar
          },
        }}
      >
        <img
          src={bannerLink}
          alt="Full Size"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </Modal>
    </div>
    
  )}
              <DataTable
                value={trainings}
                className="no-borders"
                rowClassName="hovered-row"
              >
                <Column
                  body={rowNumberTemplate}
                  header=""
                  style={{ width: "3em", textAlign: "center" }}
                />
                <Column field="name" header="Trainings" sortable></Column>
                <Column field="type" header="Type"></Column>
              </DataTable>
            </AccordionTab>
            <AccordionTab
              header=<div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <div>Videos</div>
                <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>/</div>
                <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                  {videosLength}
                </div>
              </div>
            >         
              <DataTable
                value={videos}
                className="no-borders"
                rowClassName="hovered-row"
              >
                <Column
                  body={rowNumberTemplate}
                  header=""
                  style={{ width: "3em", textAlign: "center" }}
                />
                <Column field="videoName" header="Videos" sortable></Column>
               
              </DataTable>
            </AccordionTab>
            <AccordionTab
              header=<div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <div>Devices</div>
                <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>/</div>
                <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                  {devicesLength}
                </div>
              </div>
            >
              <DataTable
                value={devices}
                className="no-borders"
                rowClassName="hovered-row"
              >
                <Column
                  body={rowNumberTemplate}
                  header=""
                  style={{ width: "3em", textAlign: "center" }}
                />
                <Column field="name" header="Devices"></Column>
                <Column field="model" header="Model"></Column>
                <Column field="userName" header="User" sortable></Column>
              </DataTable>
            </AccordionTab>
            <AccordionTab
              header=<div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <div>Users</div>
                <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>/</div>
                <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                  {usersLength}
                </div>
              </div>
            >
              <div className="p-mb-4">
                <div className="p-input-icon-left">
                  {/* <i className="pi pi-search" /> */}
                  <InputText
                    type="search"
                    value={globalFilter}
                    onChange={(e) => onGlobalFilterChange(e.target.value)}
                    placeholder="Global Search"
                  />
                </div>
                <Button
                  className="button-able"
                  style={{ float: "right" }}
                  onClick={() => {
                    setAddUserDialogVisible(true);
                  }}
                >
                  <div className="button-text">Send user invite</div>
                </Button>
              </div>
              <DataTable
                value={filteredUsersMemo}
                className="no-borders"
                rowClassName="hovered-row"
                style={{ marginTop: "15px" }}
                globalFilterFields={["name", "email", "role", "status"]}
              >
                <Column
                  body={rowNumberTemplate}
                  header=""
                  style={{ width: "3em", textAlign: "center" }}
                />
                <Column field="name" header="Name" sortable></Column>
                <Column field="email" header="Email"></Column>
                <Column
                  field="role"
                  header="Role"
                  body={(rowData) => (
                    <Dropdown
                      value={rowData.role}
                      options={roles}
                      style={{ width: "210px" }}
                      onChange={(e) => {
                        const requestBody = {
                          companyId: id,
                          userId: rowData.id,
                          newRole: e.value,
                        };
                        console.log("Request body:", requestBody);
                        const token = localStorage.getItem("token");
                        let inv = axios.post(
                          `${process.env.REACT_APP_BACKEND_LINK}/change/user/role`,
                          requestBody,
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "token"
                              )}`,
                            },
                          }
                        );

                        toast.success("Role changed successfully");
                        fetchData();
                      }}
                      placeholder={getRowRole(rowData.role)}
                    />
                  )}
                />

                <Column
                  field="status"
                  header="Status"
                  sortable
                  body={(rowData) => (
                    <Dropdown
                      value={rowData.status}
                      options={statuses}
                      style={{ width: "231px" }}
                      onChange={(e) => {
                        const requestBody = {
                          companyId: id,
                          userId: rowData.id,
                          newStatus: e.value,
                        };
                        console.log("Request body:", requestBody);
                        const token = localStorage.getItem("token");
                        let inv = axios.post(
                          `${process.env.REACT_APP_BACKEND_LINK}/change/user/status`,
                          requestBody,
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "token"
                              )}`,
                            },
                          }
                        );

                        toast.success("Status changed successfully");
                        fetchData();
                      }}
                      placeholder={rowData.status}
                    />
                  )}
                />
              </DataTable>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
      <Dialog
        visible={isDeleteDialogVisible}
        onHide={() => {
          setIsDeleteDialogVisible(false);
        }}
        header={
          <div style={{ marginTop: "-25px" }}>
            Are you sure, you want to delete company?
          </div>
        }
        footer={
          <div
            className="p-dialog-footer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              label="Cancel"
              className="button-ablec"
              onClick={() => {
                setIsDeleteDialogVisible(false);
              }}
            />
            <Button
              label="Delete"
              onClick={handleDelete}
              className="button-delete"
            />
          </div>
        }
        dismissableMask={true}
        closable={false}
        className="centered-dialog"
        style={{ width: "440px", height: "173px" }}
      >
        <div className="p-fluid"></div>
      </Dialog>
      <Dialog
        visible={addUserDialogVisible}
        onHide={() => setAddUserDialogVisible(false)}
        header={<div style={{ marginTop: "-25px" }}>Invite user</div>}
        footer={
          <div
            className="p-dialog-footer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              label="Cancel"
              className="button-ablec"
              onClick={() => setAddUserDialogVisible(false)}
            />
            <Button
              label="Invite"
              onClick={handleAddUser}
              className="button-able"
              disabled={!emails.length} // Disabled if no emails
            />
          </div>
        }
        dismissableMask={true}
        closable={false}
        className="centered-dialog"
        style={{ width: "440px" }}
      >
        <div className="p-fluid">
          <div style={{ marginTop: "15px" }}>User emails</div>
          <Chips
            value={emails}
            onChange={(e) => setEmails(e.value)}
            placeholder="Enter emails and press Enter"
            style={{ width: "100%" }} // Added margin for space between emails and other content
          />
        </div>
      </Dialog>
      <Dialog
        visible={visible}
        onHide={onHide}
        closable={false}
        style={{ width: "440px" }}
        header={
          <div
            style={{ marginTop: "-25px", fontSize: "20px", fontWeight: "600" }}
          >
            Generate invoice
          </div>
        }
        footer={
          <div
            className="p-dialog-footer"
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button
              label="Close"
              className="generate-invoice-close"
              onClick={() => {
                setVisible(false);
                setSuccess(false);
                fetchData();
              }}
            />
            <Button
              label={
                loading
                  ? "Generating..."
                  : success
                  ? "Download PDF"
                  : "Generate Invoice"
              }
              onClick={async () => {
                if (
                  !companyName ||
                  !billingAddress ||
                  !contactName ||
                  !rowData.userEmail
                ) {
                  toast.error("Please fill all the required fields.");
                  return;
                }
                if (!success) {
                  // If not successful, generate PDF
                  await generateInvoice();
                } else {
                  // If successful, initiate download
                  downloadPDF();
                }
              }}
              className={`generate-invoice-generate ${
                loading ? "p-button-loading" : ""
              }`}
              disabled={loading}
            />
          </div>
        }
        modal
        className="p-fluid"
      >
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Licenses allocated
          </div>
          <div className="row">
            <InputNumber
              value={value}
              onValueChange={(e) => {
                setValue(e.value);
                if (invoiceType == "/month") {
                  setTotalCost(e.value * unitPrice);
                } else {
                  let updatedUnitPrice = 1080; // Default unit price

                  if (e.value >= 10 && e.value < 50) {
                    // If evalue is between 20 and 50, set unitPrice to default - 24
                    updatedUnitPrice = 1056;
                  } else if (e.value >= 50) {
                    // If evalue is greater than or equal to 50, set unitPrice to default - 48
                    updatedUnitPrice = 1032;
                  }

                  // Set unitPrice based on conditions
                  setUnitPrice(updatedUnitPrice);

                  // Calculate totalCost after setting unitPrice
                  setTotalCost(e.value * updatedUnitPrice);
                }
              }}
              min={0}
              max={999}
              showButtons
              buttonLayout="horizontal"
              style={{ width: "130px" }}
              decrementButtonIcon="pi pi-minus-circle"
              incrementButtonIcon="pi pi-plus-circle"
              decrementButtonClassName="custom-decrement-button"
              incrementButtonClassName="custom-increment-button"
              disabled={success || loading}
            />
            <div
              style={{
                marginTop: "13px",
                marginLeft: "-10px",
                fontSize: "12px",
              }}
            >
              headsets
            </div>
            <div
              style={{
                marginTop: "10px",
                marginLeft: "90px",
                fontWeight: "520",
              }}
            >
              {invoiceType === "/year"
                ? `$${(totalCost / 12).toLocaleString()}`
                : `$${totalCost.toLocaleString()}`}
            </div>
            <div
              style={{
                marginTop: "13px",
                marginLeft: "-15px",
                fontSize: "12px",
              }}
            >
              /month
            </div>
          </div>
        </div>
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Billing cycle
          </div>
          <Dropdown
            style={{ width: "100%" }}
            disabled={success || loading}
            placeholder={
              invoiceType == "/month" ? (
                <div className="row">
                  <div>Monthly</div>
                  <div
                    style={{
                      color: "rgba(46, 58, 75, 0.7)",
                      marginLeft: "70px",
                    }}
                  >
                    $99 per headset/month
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div>Annually</div>
                  <div
                    style={{
                      color: "rgba(46, 58, 75, 0.7)",
                      marginLeft: "70px",
                    }}
                  >
                    ${unitPrice} per headset/year
                  </div>
                </div>
              )
            }
            options={billing}
            onChange={(e) => {
              setInvoiceType(e.value);
              if (e.value == "/month") {
                setUnitPrice(59);
                setInvoiceType("/month");
                setPaymentType(e.value);
                setTotalCost(0);

                setValue(0);
              } else {
                setUnitPrice(1080);
                setInvoiceType("/year");
                setPaymentType(e.value);
                setTotalCost(0);
                setValue(0);
              }
              // setTotalCost(value * unitPrice);
            }}
          />
        </div>
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Company name*
          </div>
          <InputText
            disabled={success || loading}
            style={{ width: "100%" }}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Billing address*
          </div>
          <InputText
            disabled={success || loading}
            style={{ width: "100%" }}
            onChange={(e) => setBillingAddress(e.target.value)}
          />
        </div>
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Contact name*
          </div>
          <InputText
            disabled={success || loading}
            style={{ width: "100%" }}
            onChange={(e) => setContactName(e.target.value)}
          />
        </div>
        <div className="column">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>Email*</div>
          <InputText
            style={{ width: "100%" }}
            disabled={success || loading}
            value={rowData.userEmail}
          />
        </div>
        <div className="row">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>Total/Year</div>
          <div
            style={{
              fontWeight: "550",
              marginTop: "15px",
              marginLeft: "auto",
              fontSize: "18px",
            }}
          >
            ${totalCost.toLocaleString()}
          </div>
        </div>
        <div className="row">
          <div style={{ fontWeight: "520", marginTop: "15px" }}>
            Send to the client
          </div>
          <div
            style={{
              fontWeight: "550",
              marginTop: "15px",
              marginLeft: "auto",
              fontSize: "18px",
            }}
          >
            <InputSwitch
              checked={sendToTheClient}
              disabled={success || loading}
              // style={{ transform: "scale(0.7)" }}
              onChange={(e) => setSendToTheClient(e.value)}
            />
          </div>
        </div>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
export default CompanyProfile;
