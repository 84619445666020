import axios from "axios";

export default function SetInvoice(    
    companyId:string,
    licenses:any,
    price:any
){   
    const requestBody = {
        companyId,
        number: licenses,
        price
      };     
    return axios.post(
        `${process.env.REACT_APP_BACKEND_LINK}/change/licenses`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "token"
            )}`,
          },
        }
      );
}
