import axios from "axios";

export default function GetCompany(    
    companyId: string
){   
    return axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/getOneCompany/${localStorage.getItem("id")}/${companyId}`,        
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
}
