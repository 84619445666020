import axios from "axios";

export default function GetProgress(    
    number: number
){   
    return axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/progress/pdf/${number}`,        
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
}
