
import React, { useState } from "react";
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

interface DropdownEntityProps<T>{
    initialEntity: any;
    listOf: T[];
    className: string;
    placeholder?: string;
    optionLabel: any;
    onChange?: (value: any) => void;
    optionTemplate?: (option: T) => React.ReactNode;
}

export default function DropdownEntity<T extends Record<string, any>>({
    initialEntity,
    listOf,
    className,
    placeholder,
    optionLabel,
    onChange,
    optionTemplate,
}: DropdownEntityProps<T>) {
    const[selectedEntity, setSelectedEntity] = useState<T>( initialEntity);

    const handleChange = (e: DropdownChangeEvent) => {
        setSelectedEntity(e.value);
        onChange?.(e.value)
    }

    return (
        <div>
            <Dropdown    
                className={className}    
                placeholder={placeholder}   
                value={selectedEntity}
                onChange={handleChange}
                options={listOf}
                optionLabel={optionLabel as string}
                itemTemplate={optionTemplate}
            />
        </div>
    )

}